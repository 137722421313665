import React from "react";
import Image from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import StarIcon from "../../assets/images/star-icon.png";
import { useIntl, Link } from "gatsby-plugin-react-intl";

const query = graphql`
  {
    allContentfulProduct(limit: 4) {
      nodes {
        name
        slug
        nameFr
        image {
          fluid(quality: 60, maxWidth: 250, maxHeight: 250) {
            ...GatsbyContentfulFluid
          }
        }
        imageFr {
          fluid(quality: 60, maxWidth: 250, maxHeight: 250) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;

const ProductsList = () => {
  const intl = useIntl();

  const {
    allContentfulProduct: { nodes: products },
  } = useStaticQuery(query);

  return (
    <section className="scientist-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <h2 className="sub-title">
            <img src={StarIcon} alt="banner" />{" "}
            {intl.formatMessage({ id: "products" })}
          </h2>
        </div>
        <div className="row">
          {products?.map((product) => {
            const productName =
              intl.locale === "en"
                ? product?.name
                : product?.nameFr || product?.name;
            const productImage =
              intl.locale === "en"
                ? product?.image?.fluid
                : product?.imageFr?.fluid || product?.image?.fluid;
            return (
              <div className="col-lg-3 col-md-4 col-sm-6 col-xs-11 home-products">
                <Link
                  to={`/${intl.locale === "en" ? "product" : "produit"}/${
                    product.slug
                  }`}
                >
                  <div className="image">
                    <Image
                      className="product-images"
                      imgStyle={{ objectFit: "contain" }}
                      fluid={productImage}
                      alt={productName}
                    />
                  </div>
                  <div className="content">
                    <h3>{productName}</h3>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
        <div className="row mt-5">
          <Link to="/products" className="default-btn text-center mt-5 m-auto ">
            <i className="flaticon-right"></i>
            All {intl.formatMessage({ id: "products" })} <span></span>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ProductsList;

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Image from "gatsby-image";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import starIcon from "../../assets/images/star-icon.png";

const OurServices = () => {
  const {
    allContentfulTreatment: { nodes: treatments },
  } = useStaticQuery(query);
  const intl = useIntl();

  return (
    <section className="services-area mt-5 bg-f1f8fb">
      <div className="container">
        <div className="section-title">
          <h2 className="sub-title mt-3">
            <img src={starIcon} alt="banner" />
            {intl.formatMessage({ id: "treatments" })}
          </h2>
        </div>

        <div className="row">
          {treatments.map((treatment) => {
            const treatmentName =
              intl.locale === "en"
                ? treatment?.name
                : treatment?.name_fr || treatment?.name;
            const treatmentImage =
              intl.locale === "en"
                ? treatment?.image?.fluid
                : treatment?.imageFr?.fluid || treatment?.image?.fluid;
            return (
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="single-services-box">
                  <Link to={`/treatments/${treatment.slug}`}>
                    <div className="icon">
                      <Image fluid={treatmentImage} alt={treatmentName} />
                    </div>
                    <h3>{treatmentName}</h3>
                    <Link
                      to={`/treatments/${treatment.slug}`}
                      className="read-more-btn"
                    >
                      {intl.formatMessage({ id: "read_more" })}
                      <i className="flaticon-right"></i>
                    </Link>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="lines">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </section>
  );
};

export const query = graphql`
  {
    allContentfulTreatment {
      nodes {
        name
        slug
        name_fr
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        imageFr {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;

export default OurServices;

import React from 'react';
import NavbarTwo from "../components/App/NavbarTwo"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import MainBanner from '../components/MachineLearningAISolutions/MainBanner';
import Services from '../components/MachineLearningAISolutions/Services';
import ProductCategories from '../components/MachineLearningAISolutions/ProductCategories';
import ProductsList from '../components/MachineLearningAISolutions/ProductsList';


const Home = () => {
    return (
        <Layout title='Home Page' canonical={process.env.GATSBY_MAIN_URL}>
            <NavbarTwo />
            <MainBanner />
            <ProductsList/>
            <Services />
            <ProductCategories />
            <Footer />
        </Layout>
    );
}

export default Home;
import React from "react";
import Image from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";

const MainBanner = () => {
  const {
    allContentfulMainSlider: { nodes: banners },
  } = useStaticQuery(query);
  const intl = useIntl();

  const currentBanner =
    intl.locale === "en" ? banners[0].bannerEnglish : banners[0].bannerFrench;
  return (
    <React.Fragment>
      <CarouselProvider
        naturalSlideWidth={45}
        naturalSlideHeight={25}
        totalSlides={currentBanner?.length}
        visibleSlides={1}
      >
        <Slider>
          {currentBanner?.map((banner, i) => {
            return (
              <Slide index={i}>
                <div key={i} className="main-banner-item item-bg1">
                  <Image
                    fluid={banner?.fluid}
                    className="main-banner-image"
                    alt={`Medart Main Banner ${i}`}
                  />
                </div>
              </Slide>
            );
          })}
        </Slider>
      </CarouselProvider>
    </React.Fragment>
  );
};

export const query = graphql`
  {
    allContentfulMainSlider {
      nodes {
        bannerEnglish {
          fluid(quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
        bannerFrench {
          fluid(quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;

export default MainBanner;
